'strict'

import filters from '@/filters';

import vcAttachmentView from '@/components/vcAttachmentView'

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      customRender: (value, row) => {
        let THUMB;
        if (row.thumbnail) {
          THUMB = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              attrs: { src: `${row.thumbnail.src}?x-oss-process=image/resize,m_fixed,limit_0,h_48,w_74` },
              on: {
                click: () => {
                  ctx.$dialog(vcAttachmentView, {
                    data: row.thumbnail
                  }, {
                    title: row.thumbnail.name,
                    footer: () => null
                  });
                }
              }
            })
          ]);
        }

        const USER = h('a-tooltip', {
          props: { title: `作者: ${row.creator ? row.creator.display_name : '--'}` }
        }, [
          h('a-icon', {
            props: { type: 'user' },
            class: [ 'm-r-5' ]
          }),
          h('span', row.creator ? row.creator.display_name : '--')
        ]);

        const VIEW = h('a-tooltip', {
          props: { title: `查阅数: ${row.view_count}` }
        }, [
          h('a-icon', {
            props: { type: 'eye' },
            class: [ 'm-r-5' ]
          }),
          h('span', row.view_count)
        ]);
        
        // const LIKE = h('a-tooltip', {
        //   props: { title: `点赞数: ${row.like_count}` }
        // }, [
        //   h('a-icon', {
        //     props: { type: 'like' },
        //     class: [ 'm-r-5' ]
        //   }),
        //   h('span', row.like_count)
        // ]);

        const COMMENT = h('a-tooltip', {
          props: { title: `评论数: ${row.comment_count}` }
        }, [
          h('a-icon', {
            props: { type: 'message' },
            class: [ 'm-r-5' ]
          }),
          h('span', row.comment_count)
        ]);

        return h('div', {
          class: [ 'vc-table-wrap' ]
        }, [
          THUMB,
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('strong', {
              class: [ 'line-1' ]
            }, row.title),
            h('div', {
              class: [ 'm-t-5', 'hint-text' ],
            }, [
              USER,
              h('a-divider', { props: { type: 'vertical' } }),
              VIEW,
              h('a-divider', { props: { type: 'vertical' } }),
              // LIKE,
              // h('a-divider', { props: { type: 'vertical' } }),
              COMMENT
            ])
          ])
        ]);
      }
    },
    {
      title: '分类',
      dataIndex: 'category',
      key: 'category',
      width: 100,
      customRender: (value, row) => {
        return h('div', row.categories.map(item => {
          return item.name
        }));
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 85,
      filters: [
        // { text: '待审核', value: 'pending' },
        { text: '已发布', value: 'publish' },
        { text: '草稿', value: 'draft' },
      ],
    },
    // {
    //   title: '作者',
    //   dataIndex: 'creator',
    //   key: 'creator',
    //   width: 80,
    //   customRender: (value, row) => {
    //     return [
    //       h('span', row.creator.display_name)
    //     ]
    //   }
    // },
    {
      title: '发布时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.created_at))
      }
    },
    {
      title: '排序',
      dataIndex: 'menu_order',
      key: 'menu_order',
      width: 80,
      align: 'center',
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
    }
  ];
}