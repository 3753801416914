'strict'

import filters from '@/filters';

import vcAttachmentView from '@/components/vcAttachmentView'

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      width: 220,
      customRender: (value, row) => {
        let THUMB;
        if (row.thumbnail && row.thumbnail[0]) {
          THUMB = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              attrs: { src: `${row.thumbnail[0].src}?x-oss-process=image/resize,m_fixed,limit_0,h_48,w_74` },
              on: {
                click: () => {
                  ctx.$dialog(vcAttachmentView, {
                    data: row.thumbnail[0]
                  }, {
                    title: row.thumbnail[0].name,
                    footer: () => null
                  });
                }
              }
            })
          ]);
        }

        return h('div', {
          class: [ 'vc-table-wrap' ]
        }, [
          THUMB,
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('strong', {
              class: [ 'line-1' ]
            }, row.title),
            h('strong', {
              class: [ 'block', 'line-1' ]
            }, row.title_en)
          ])
        ]);
      }
    },
    {
      title: '描述',
      dataIndex: 'excerpt',
      key: 'excerpt',
      maxWidth: 300,
      customRender: (value, row) => {
        return h('div', {
          domProps: {
            innerHTML: row.excerpt,
          },
          class: [ 'line-3' ]
        });
      },
    },
    {
      title: '排序',
      dataIndex: 'menu_order',
      key: 'menu_order',
      align: 'center',
      width: 80,
      sorter: true,
    },
    // {
    //   title: '查阅',
    //   dataIndex: 'view_count',
    //   key: 'view_count',
    //   align: 'center',
    //   width: 80,
    //   sorter: true,
    // },
    // {
    //   title: '状态',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 85,
    //   filters: [
    //     { text: '待审核', value: 'pending' },
    //     { text: '已发布', value: 'publish' },
    //   ],
    // },
    {
      title: '发布时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.created_at))
      }
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
      
      /*
      customRender: () => {
        return [
          <span>sss</span>
          // <a on-click={ () => this.newRecord(row.id, index) }>Edit</a>,
          // <a-divider type="vertical" />,
          // <a-popconfirm v-if="dataSource.length" title="Sure to delete?" on-confirm="() => onDelete(record.key)">
          //   <a href="javascript:;">Delete</a>
          // </a-popconfirm>,
          // <a on-click={ () => this.newRecord(row.id, index) }>Delete</a>
        ]
      }
      */
    }
  ];
}