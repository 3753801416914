'strict'

import filters from '@/filters';

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '用户',
      dataIndex: 'user',
      key: 'user',
      customRender: (value, row) => {
        let AVATAR;
        if (row.avatar) {
          AVATAR = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              attrs: { src: `${row.avatar.src}` },
              on: {
                click: () => {
                  console.log('aaa')
                  // store.dispatch('ui/modal', {
                  //   show: true,
                  //   title: row.avatar.name,
                  //   component: {
                  //     name: 'ComponentAttachment',
                  //     data: { guid: row.avatar.src }
                  //   }
                  // });
                }
              }
            })
          ]);
        }

        return h('div', {
          class: [ 'vc-table-wrap' ]
        }, [
          AVATAR,
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('strong', {
              class: [ 'line-1' ]
            }, row.display_name)
          ])
        ]);
      }
    },
    {
      title: '手机',
      dataIndex: 'mobile',
      key: 'mobile',
      //- width: 120,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
      //- width: 180,
      // filters: [
      //   { text: 'Gmail', value: 'gmail' },
      //   { text: 'Yahoo', value: 'yahoo' },
      //   { text: 'QQ', value: 'qq' },
      //   { text: '163', value: '163' },
      // ],
    },
    {
      title: '可登录后台',
      dataIndex: 'activation_key',
      key: 'activation_key',
      width: 100,
      customRender: (value, row) => {
        return h('div', {}, [
          h('a-switch', {
            props: {
              defaultChecked: row.activation_key === '1',
              disabled: row.code <= 5
            },
            on: {
              change: () => ctx.handleChange(row.id)
            }
          }, ''),
          // h('strong', {}, row.activation_key)
        ]);
      }
    },
    {
      title: 'AI检索次数',
      dataIndex: 'ai_count',
      key: 'ai_count',
      width: 120,
      align: 'center'
    },
    // {
    //   title: 'gender',
    //   dataIndex: 'gender',
    //   key: 'gender',
    //   filters: [
    //     { text: 'Male', value: 'male' },
    //     { text: 'Female', value: 'female' },
    //   ],
    // },
    {
      title: '注册时间',
      dataIndex: 'registered',
      key: 'registered',
      width: 100,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.registered))
      }
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
    }
  ];
}