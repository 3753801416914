<template lang="pug">
section
  a-form-model(layout="inline" style="float: right; margin-top: -55px;")
    a-form-model-item.m-r-10
      a-input(v-model="keyword" :allow-clear="true" placeholder="搜索")
        a-icon(slot="prefix" type="search" style="color:rgba(0,0,0,.25)")
    a-form-model-item.m-r-0
      a-button(type="primary" @click="onSearch") 搜索

  .vc-table
    
    ul.table-extra(slot="extra" v-if="extras.length")
      li(v-for="(item, index) in extras" :key="index")
        template(v-if="item.component && item.key === 'x-table-size'")
          component(:is="item.component" :data="{ default: table_size }" @change="handleComponentChange")
        template(v-else-if="item.component && item.key === 'x-table-columns'")
          component(:is="item.component" :data="{ columns }" @change="handleComponentChange")
        template(v-else-if="item.component")
          component(:is="item.component" :data="item" @change="handleComponentChange")
        template(v-else)
          a.btn-icon-link(@click="handleComponentClick(item.key)")
            a-icon.icon(:type="item.icon")

    //- 过滤器
    .table-filter(v-if="filter && filters.length")
      a-form(:form="form" @submit="formSubmit")
        a-row(:gutter="[ 0, 0 ]")
          a-col(:span="8" :style="{ display: index < count ? 'block' : 'none' }" v-for="(item, index) in filters" :key="index")
            a-form-item(
              :label="item.label"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol")
              a-input(v-decorator="[`note_${index}`, { rules: [{ required: true, message: `Please input your note_${index}!` }] }]")

          a-col(:span="8")
            a-form-item(
              :label-col="formTailLayout.labelCol"
              :wrapper-col="formTailLayout.wrapperCol")
              a-button(@click="() => { this.form.resetFields() }") 重置
              a-button.m-l-15(type="primary" html-type="submit") 搜索
              a.m-l-15(@click="() => { expand = !expand }") {{ expand ? '收拢' : '展开' }}
                a-icon(:type="expand ? 'up' : 'down'")

    //- 头部表格控件
    .table-nav
      //- pre {{ data.pagination }}

      //- 头部批量操作控件
      .do-action
        a-select.left(
          allow-clear
          v-model="action"
          :placeholder="action_placeholder"
          :disabled="!actions.length"
          style="width: 120px")
          a-select-option(:value="item.value" v-for="(item, index) in actions" :key="index") {{ item.label }}
        a-button.m-l-5(
          type="primary"
          :disabled="!action || !table_selected_row_keys.length"
          @click="actionClick") {{ action_button }}
        
      //- 分页
      a-pagination.right(
        v-model="pagination.current"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :show-less-items="pagination.showLessItems"
        :show-size-changer="pagination.showSizeChanger"
        :page-size-options="pagination.pageSizeOptions"
        :show-total="pagination.showTotal"
        @change="pageChange"
        @showSizeChange="pageSizeChange")
        
    //- 表格
    a-table(
      ref="table"
      :row-key="record => record.id"
      :size="table_size"
      :scroll="scroll"
      :loading="loading"
      :columns="columns"
      :data-source="source"
      :pagination="false"
      :row-selection="{ selectedRowKeys: table_selected_row_keys, onChange: table_row_selection.onChange }"
      @change="tableChange")

    //- 底部表格控件
    .table-nav
    
      //- 底部操作控件
      .do-action
        a-button(type="primary" disabled @click="handleEport") 数据导出
        a-button.m-l-5(disabled @click="handleImport") 数据导入

      //- 分页
      a-pagination.right(
        show-less-items
        show-size-changer
        v-model="pagination.current"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :show-less-items="pagination.showLessItems"
        :show-size-changer="pagination.showSizeChanger"
        :page-size-options="pagination.pageSizeOptions"
        :show-total="pagination.showTotal"
        @change="pageChange"
        @showSizeChange="pageSizeChange")

</template>

<script>
import _extends from 'babel-runtime/helpers/extends';

import { debounce } from 'lodash'

import tableSize from '@/components/tableSize'
import tableColumns from '@/components/tableColumns'

export default {
  name: 'vc-table',
  components: {
    'table-size': tableSize,
    'table-columns': tableColumns,
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    count() {
      return this.expand ? this.filters.length : 2;
    },
    filters() {
      return _extends([], this.data.filters);
    },
    actions() {
      return _extends([], this.data.actions);
    },
    pagination() {
      return _extends({
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: [ '10', '20', '30', '40', '50' ],
        showLessItems: true,
        showSizeChanger: true,
        hideOnSinglePage: false,
        showTotal: total => `总共${total}条数据`
      }, this.data.pagination);
    },
    loading() {
      return this.data.loading || false;
    },
    columns() {
      return _extends([], this.data.columns);
    },
    source() {
      return _extends([], this.data.source);
    },
    scroll() {
      return _extends({}, this.data.scroll);
    },
    extras() {
      return _extends([], this.data.extra);
    }
  },
  data() {
    return {
      filter: false,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      },
      formTailLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16, offset: 6 },
      },
      expand: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),

      action: undefined,
      action_button: '执行',
      action_placeholder: '批量操作',

      table_size: 'default',
      table_state: {},
      table_selected_row_keys: [],
      table_row_selection: {
        onChange: keys => {
          this.table_selected_row_keys = keys;
        }
      },

      keyword: undefined
    }
  },
  methods: {
    getData() {
      return this.source
    },
    refresh() {
      //- console.log('vc table refresh')

      const { api } = this.data;
      if (api) {
        if (this.data.loading === true) return;
        this.data.loading = true;

        api({
          keyword: this.keyword,
          page: this.data.pagination.current,
          limit: this.data.pagination.pageSize,
          ...this.table_state
        }).then(res => {
        
          this.data.loading = false;
          //- this.data.pagination.current_page = res.current_page;
          //- this.data.pagination.per_page = res.per_page;
          this.data.pagination.total = res.total;
          this.data.source = res.data;
        }).catch(err => {
          this.data.loading = false;
          this.$message.error(err.message)
        });
      }
    },
    formSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) console.log('Received values of form: ', values);
      });
    },
    
    /*********************************************************
     * @description 数据导出
     *********************************************************/
    handleEport() {
      console.log('数据导出')
    },

    /*********************************************************
     * @description 数据导入
     *********************************************************/
    handleImport() {
      console.log('数据导入')
    },
    
    /*********************************************************
     * @description 翻页
     *********************************************************/
    pageChange(current) {
      this.data.pagination.current = current;
      this.refresh();
      this.handleComponentClick('table-reload');
    },

    /*********************************************************
     * @description 改变每页显示数量
     *********************************************************/
    pageSizeChange(current, pageSize) {
      this.data.pagination.current = current;
      this.data.pagination.pageSize = pageSize;
      this.refresh();
    },

    /*********************************************************
     * @description 表格批量操作控件
     *********************************************************/
    actionClick() {
      if (!this.action || !this.table_selected_row_keys.length) return;
      this.$emit('change', {
        action: { do: this.action, data: this.table_selected_row_keys }
      });
    },

    // 表格控件
    handleComponentChange(e) {
      console.log(`handleComponentChange(${e})`, e);

      if (e['table-size']) {
        this.table_size = e['table-size'];
      }
    },
    handleComponentClick(key) {
      switch(key) {
        case 'table-search':
          this.filter = !this.filter
        break;
        case 'table-reload':
          this.$emit('change', {
            pagination: this.pagination,
            ...this.table_state
          });
        break;
      }
    },

    
    //- , { currentDataSource }
    tableChange(pagination, filters, sorter) {
      this.table_state = {
        sortField: sorter && sorter.order ? sorter.field : undefined,
        sortOrder: sorter ? sorter.order : undefined,
        ...filters
      }
      this.refresh();
    },

    

    /*********************************************************
    * @description 搜索
    *********************************************************/
    // 延迟搜索
    onSearchDebounce: debounce(function() {
      if (this.keyword) this.onSearch();
    }, 500),

    // 清空搜索
    onClear() {
      this.pagination.current = 1;
      this.getData();
    },

    // 搜索
    onSearch() {
      if (!this.keyword) this.keyword = undefined;
      //- if (!this.keyword) return;
      //- this.pagination.current = 1;
      //- this.refresh();
      this.pageChange(1)
    },
  },
  created(){
    this.refresh();

    const { size } = this.data;
    if (size) this.table_size = size;
  }
}
</script>

<style lang="less">
.vc-table {
  position: relative;

  .table-extra {
    padding: 0; margin: 0;
    top: -40px; right: 0;
    position: absolute;

    li {
      margin: 0 2px;
      display: block;
      float: left;

      a {
        display: block;
        color: #4b4b4b;
        text-align: center;
        line-height: 30px;
        width: 26px;
        height: 26px;
      }
    }
    .btn-icon-link {
      .icon { font-size: 16px;}
    }
  }

  .table-filter {
    background: #fff;
    padding: 20px 15px 10px;
    
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .table-nav {
    margin: 15px 0;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .do-action {
      float: left;
    }
  }

  .ant-table {
    border: solid 1px #e8e8e8;

    .ant-table-tbody {
      > tr {
        &:last-child {
          > td {
            border-bottom: none;
          }
        }
        &.ant-table-row-selected {
          > td {
            background-color: #e6f7ff;
          }
        }
        &:nth-child(2n) {
          background-color: #fafafa;
        }
      }
    }

    // class
    .vc-table-wrap {
      width: 100%;
    }
    .vc-table-thumb {
      float: left;
      width: 74px; height: 48px; overflow: hidden;
      position: relative;

      img {
        width: 100%;
        position: relative; top: 50%; left: 50%;
        transform: translate3d(-50%, -50%, 0px);
      }

      .anticon {
        position: absolute; top: 50%; left: 50%;
        display: block;
        transform: translate3d(-50%, -50%, 0);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        border: solid 1px #eee;
        pointer-events: none;
      }

      + .vc-table-info {
        margin-left: 86px;
      }
    }
    .vc-table-info {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>