<template lang="pug">
a-dropdown(:trigger="['click']" placement="bottomRight")
  a.btn-icon-link
    a-icon.icon(:type="data.icon")
  a-menu(slot="overlay" :selectedKeys="selectedKeys" @click="dropdownClick" style="width: 70px;")
    template(v-for="(item, index) in sizes")
      a-menu-item(:key="item.value")
        span {{ item.label }}
</template>

<script>
export default {
  name: 'TableSize',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedKeys: [],
      sizes: [
        { label: '默认', value: 'default' },
        { label: '中等', value: 'middle' },
        { label: '紧凑', value: 'small' }
      ]
    }
  },
  methods: {
    dropdownClick(e) {
      this.selectedKeys = [ e.key ];
      this.$emit('change', { [this.data.key]: e.key });
    }
  },
  created() {
    this.selectedKeys = (this.data && this.data.data && this.data.data.default) ? [ this.data.data.default ] : [ this.sizes[0].value ];
  }
}
</script>