'strict'
// import store from '@/store';

// import { Modal } from 'ant-design-vue';
import filters from '@/filters';

import vcAttachmentView from '@/components/vcAttachmentView'

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '文件',
      dataIndex: 'file',
      key: 'file',
      customRender: (value, row) => {
        let THUMB;
        if ([ 'image/jpeg', 'image/gif', 'image/png', 'image/webp' ].includes(row.mime_type)) {
          if (row.guid.indexOf('aliyuncs.com')) {
            THUMB = h('img', {
              class: [ 'pointer' ],
              attrs: { src: `${row.guid}?x-oss-process=image/resize,m_fixed,limit_0,h_48,w_74` },
              on: {
                click: () => {
                  ctx.$dialog(vcAttachmentView, {
                    data: row
                  }, {
                    title: row.name,
                    footer: () => null
                  });
                }
              }
            });
          } else {
            THUMB = h('img', {
              attrs: { src: row.guid }
            });
          }
        } else if ([ 'application/pdf' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-pdf' },
            class: [ 'fs-32' ]
          });
        } else if ([ 'application/vnd.ms-powerpoint' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-ppt' },
            class: [ 'fs-32' ]
          });
        } else if ([ 'application/vnd.ms-excel' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-excel' },
            class: [ 'fs-32' ]
          });
        } else if ([ 'application/msword' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-word' },
            class: [ 'fs-32' ]
          });
        } else {
          THUMB = h('a-icon', {
            props: { type: 'file-unknown' },
            class: [ 'fs-32' ]
          });
        }
        
        const USER = h('a-tooltip', {
          props: { title: `创建者: ${row.creator ? row.creator.display_name : '--'}` }
        }, [
          h('a-icon', {
            props: { type: 'user' },
            class: [ 'm-r-5' ]
          }),
          h('span', row.creator ? row.creator.display_name : '--')
        ]);

        const SIZE = h('a-tooltip', {
          props: { title: `文件大小: ${row.size || '--'}` }
        }, [
          h('a-icon', {
            props: { type: 'file' },
            class: [ 'm-r-5' ]
          }),
          h('span', row.size || '--')
        ]);

        const LINK = h('a-tooltip', {
          props: { title: `使用次数: ${row.count || '--'}` }
        }, [
          h('a-icon', {
            props: { type: 'link' },
            class: [ 'm-r-5' ]
          }),
          h('span', row.count || '--')
        ]);

        return h('div', [
          h('div', {
            class: [ 'vc-table-thumb' ],
          }, [ THUMB ]),
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('strong', {
              class: [ 'line-1' ]
            }, row.name),
            h('div', {
              class: [ 'm-t-5', 'hint-text' ],
            }, [
              USER,
              h('a-divider', { props: { type: 'vertical' } }),
              SIZE,
              h('a-divider', { props: { type: 'vertical' } }),
              LINK
            ])
          ])
        ]);
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 85,
      filters: [
        { text: '待审核', value: 'pending' },
        { text: '已发布', value: 'publish' },
      ],
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.created_at))
      }
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])
          
          // this.$confirm({
          //   title: 'Do you want to delete these items?',
          //   content: 'When clicked the OK button, this dialog will be closed after 1 second',
          //   onOk() {
          //     return new Promise((resolve, reject) => {
          //       setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          //     }).catch(() => console.log('Oops errors!'));
          //   },
          //   onCancel() {},
          // });

        ];
      },
    }
  ];
}