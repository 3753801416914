'strict'

// import filters from '@/filters';

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '图库ID',
      dataIndex: 'GroupId',
      key: 'GroupId'
    },
    {
      title: '图库名称描述',
      dataIndex: 'GroupName',
      key: 'GroupName'
    },
    {
      title: '简介',
      dataIndex: 'Brief',
      key: 'Brief'
    },
    {
      title: '最大图片数量',
      dataIndex: 'MaxCapacity',
      key: 'MaxCapacity'
    },
    {
      title: '访问限制',
      dataIndex: 'MaxQps',
      key: 'MaxQps'
    },
    {
      title: '图库类型',
      dataIndex: 'GroupType',
      key: 'GroupType'
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.$router.push({ name: 'Tiia Edit', params: { id: row.GroupId } })
            }
          }, '查看'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: 'Sure to delete?'
            },
            on: {
              confirm: () => {
                // ctx.deleteData(row.id)
                console.log(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
    }
  ];
}