'strict'

import filters from '@/filters';

import vcAttachmentView from '@/components/vcAttachmentView'

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      customRender: (value, row) => {
        let THUMB;
        if (row.gallery && row.gallery.length) {
          THUMB = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              attrs: { src: `${row.gallery[0].src}?x-oss-process=image/resize,m_fixed,limit_0,h_48,w_74` },
              on: {
                click: () => {
                  ctx.$dialog(vcAttachmentView, {
                    data: row.gallery[0]
                  }, {
                    title: row.gallery[0].name,
                    footer: () => null
                  });
                }
              }
            })
          ]);
        }

        let level = '';
        if (row.level) {
          for (let i = 0; i < row.level; ++i) {
            level = `${level}&mdash;`
          }
        }
        const LEVEL = level ? h('span', {
          domProps: {
            innerHTML: level,
          },
          class: [ 'm-r-5' ]
        }) : '';

        // const LINK = row.url ? h('a-tooltip', {
        //   props: { title: row.url }
        // }, [
        //   h('a-icon', {
        //     props: { type: 'link' },
        //     class: [ 'pointer', 'm-l-5' ]
        //   })
        // ]) : '';
        
        // const DESCRIPTION = row.description ? h('div', {
        //   class: [ 'hint-text', 'line-2', 'm-t-5' ]
        // }, row.description) : '';

        // return [
        //   LEVEL,
        //   h('strong', row.name),
        //   LINK,
        //   DESCRIPTION
        // ]

        return h('div', {
          class: [ 'vc-table-wrap' ]
        }, [
          THUMB,
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('p', {
              class: [ 'line-1' ]
            }, [
              LEVEL,
              h('strong', row.name),
              // LINK
            ]),
            // DESCRIPTION
          ])
        ]);
      }
    },
    {
      title: '名称（英文）',
      dataIndex: 'en_name',
      key: 'en_name'
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.created_at))
      }
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      width: 80,
      align: 'center',
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => ctx.deleteData(row.id, index)
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
    }
  ];
}