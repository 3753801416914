'strict'

// import filters from '@/filters';

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '时间',
      dataIndex: 'title',
      key: 'title',
      width: 220,
      customRender: (value, row) => {
        return h('div', {
          class: [ 'vc-table-wrap' ]
        }, [
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('strong', {
              class: [ 'line-1' ]
            }, row.title)
          ])
        ]);
      }
    },
    {
      title: '历程',
      dataIndex: 'excerpt',
      key: 'excerpt',
      maxWidth: 300,
      customRender: (value, row) => {
        return h('div', {
          domProps: {
            innerHTML: row.excerpt,
          },
          class: [ 'line-3' ]
        });
      },
    },
    {
      title: '排序',
      dataIndex: 'menu_order',
      key: 'menu_order',
      align: 'center',
      width: 80,
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
      
      /*
      customRender: () => {
        return [
          <span>sss</span>
          // <a on-click={ () => this.newRecord(row.id, index) }>Edit</a>,
          // <a-divider type="vertical" />,
          // <a-popconfirm v-if="dataSource.length" title="Sure to delete?" on-confirm="() => onDelete(record.key)">
          //   <a href="javascript:;">Delete</a>
          // </a-popconfirm>,
          // <a on-click={ () => this.newRecord(row.id, index) }>Delete</a>
        ]
      }
      */
    }
  ];
}