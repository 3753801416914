'strict'

import vcAttachmentView from '@/components/vcAttachmentView'

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '文件',
      dataIndex: 'file',
      key: 'file',
      customRender: (value, row) => {
        let THUMB;
        if ([ 'image/jpeg', 'image/gif', 'image/png', 'image/webp' ].includes(row.mime_type)) {
          if (row.guid.indexOf('aliyuncs.com')) {
            THUMB = h('img', {
              class: [ 'pointer' ],
              attrs: { src: `${row.guid}?x-oss-process=image/resize,m_fixed,limit_0,h_48,w_74` },
              on: {
                click: () => {
                  ctx.$dialog(vcAttachmentView, {
                    data: row
                  }, {
                    title: row.name,
                    footer: () => null
                  });
                }
              }
            });
          } else {
            THUMB = h('img', {
              attrs: { src: row.guid }
            });
          }
        } else if ([ 'application/pdf' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-pdf' },
            class: [ 'fs-32' ]
          });
        } else if ([ 'application/vnd.ms-powerpoint' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-ppt' },
            class: [ 'fs-32' ]
          });
        } else if ([ 'application/vnd.ms-excel' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-excel' },
            class: [ 'fs-32' ]
          });
        } else if ([ 'application/msword' ].includes(row.mime_type)) {
          THUMB = h('a-icon', {
            props: { type: 'file-word' },
            class: [ 'fs-32' ]
          });
        } else {
          THUMB = h('a-icon', {
            props: { type: 'file-unknown' },
            class: [ 'fs-32' ]
          });
        }

        return h('div', [
          h('div', {
            class: [ 'vc-table-thumb' ],
          }, [ THUMB ]),
          h('div', {
            class: [ 'vc-table-info' ],
          }, [])
        ]);
      }
    }
  ];
}