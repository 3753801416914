'strict'

import filters from '@/filters';

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    // {
    //   title: 'level',
    //   dataIndex: 'level',
    //   key: 'level',
    //   width: 50
    // },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      // ellipsis: true,
      customRender: (value, row) => {
        let level = '';
        if (row.level) {
          for (let i = 0; i < row.level; ++i) {
            level = `${level}&mdash;`
          }
        }
        const LEVEL = level ? h('span', {
          domProps: {
            innerHTML: level,
          },
          class: [ 'm-r-5' ]
        }) : '';

        const LINK = row.url ? h('a-tooltip', {
          props: { title: row.url }
        }, [
          h('a-icon', {
            props: { type: 'link' },
            class: [ 'pointer', 'm-l-5' ]
          })
        ]) : '';
        
        const DESCRIPTION = row.description ? h('div', {
          class: [ 'hint-text', 'line-2', 'm-t-5' ]
        }, row.description) : '';

        return [
          LEVEL,
          h('strong', row.name),
          LINK,
          DESCRIPTION
        ]
      }
    },
    {
      title: '名称（英文）',
      dataIndex: 'en_name',
      key: 'en_name'
    },
    // {
    //   title: '关联',
    //   dataIndex: 'count',
    //   key: 'count',
    //   width: 80,
    //   align: 'center',
    //   sorter: true,
    // },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.created_at))
      }
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      width: 80,
      align: 'center',
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.handleEdit(row.id)
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
    }
  ];
}