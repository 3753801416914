'strict'

import filters from '@/filters';

import vcAttachmentView from '@/components/vcAttachmentView'

export default ctx => {
  /* eslint-disable-next-line */
  const h = ctx.$createElement;

  return [
    {
      title: '产品',
      dataIndex: 'title',
      key: 'title',
      customRender: (value, row) => {
        let THUMB, THUMB2;
        if (row.thumbnail) {
          THUMB = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              // ?x-oss-process=image/resize,m_fixed,limit_0,h_48,w_74
              attrs: { src: `${row.thumbnail.src}` },
              on: {
                click: () => {
                  ctx.$dialog(vcAttachmentView, {
                    data: row.thumbnail
                  }, {
                    title: row.thumbnail.name,
                    footer: () => null
                  });
                }
              }
            })
          ]);
        } else if (row.thumbnail_url) {
          THUMB = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              attrs: { src: row.thumbnail_url }
            })
          ]);
          THUMB2 = h('div', {
            class: [ 'vc-table-thumb' ],
          }, [
            h('img', {
              class: [ 'full-width' ],
              attrs: { src: row.contentimg_url }
            })
          ]);
        }

        // const USER = h('a-tooltip', {
        //   props: { title: `作者: ${row.creator ? row.creator.display_name : '--'}` }
        // }, [
        //   h('a-icon', {
        //     props: { type: 'user' },
        //     class: [ 'm-r-5' ]
        //   }),
        //   h('span', row.creator ? row.creator.display_name : '--')
        // ]);
        
        return h('div', {
          class: [ 'vc-table-wrap' ]
        }, [
          THUMB,
          THUMB2,
          h('div', {
            class: [ 'vc-table-info' ],
          }, [
            h('strong', {
              class: [ 'line-2' ]
            }, row.title),
            h('div', {
              class: [ 'm-t-5', 'hint-text' ],
            }, [
              // USER
            ])
          ])
        ]);
      }
    },
    {
      title: '分类',
      dataIndex: 'category',
      key: 'category',
      width: 150,
      filters: [
        { text: '膏霜护肤类', value: 'ccd6e1b0-de2a-11ec-aac6-eb375546e113' },
        { text: '洗发沐浴类', value: 'd0494c20-de2a-11ec-aac6-eb375546e113' },
        { text: '婴童类', value: 'd327b3f0-de2a-11ec-aac6-eb375546e113' },
        { text: '护发美发类', value: 'd61536f0-de2a-11ec-aac6-eb375546e113' },
        { text: '赠品类', value: 'd92fbd60-de2a-11ec-aac6-eb375546e113' },
        { text: '洗涤剂', value: 'dc25a4d0-de2a-11ec-aac6-eb375546e113' },
        { text: 'PET', value: 'df540160-de2a-11ec-aac6-eb375546e113' },
        { text: '玻璃瓶', value: 'e25ef770-de2a-11ec-aac6-eb375546e113' },
        { text: '走珠瓶', value: 'e5d24c40-de2a-11ec-aac6-eb375546e113' },
        { text: '真空瓶', value: 'e88955f0-de2a-11ec-aac6-eb375546e113' },
        { text: '彩妆类', value: 'ebb51a70-de2a-11ec-aac6-eb375546e113' },
        { text: '其他', value: 'eebf2620-de2a-11ec-aac6-eb375546e113' },
      ],
      customRender: (value, row) => {
        if (!row.categories) return '';
        return h('div', row.categories.map(item => {
          return item.name
        }));
      },
    },
    // {
    //   title: '状态',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 85,
    //   filters: [
    //     { text: '待审核', value: 'pending' },
    //     { text: '已发布', value: 'publish' },
    //   ],
    // },
    {
      title: '发布时间',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      align: 'center',
      sorter: true,
      customRender: (value, row) => {
        return h('time', filters.date(row.created_at))
      }
    },
    {
      title: '排序',
      dataIndex: 'menu_order',
      key: 'menu_order',
      width: 80,
      align: 'center',
      sorter: true,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      customRender: (value, row, index) => {
        return [
          h('a', {
            on: {
              click: () => ctx.$router.push({
                name: 'Product Edit',
                params: {
                  id: row.id
                },
                query: {
                  paged: ctx.$route.query.paged
                }
              })
            }
          }, '编辑'),

          h('a-divider', { props: { type: 'vertical' } }),

          h('a-popconfirm', {
            props: {
              title: '确定删除?'
            },
            on: {
              confirm: () => {
                ctx.deleteData(row.id)
                console.log(row.id, index)
              }
            }
          }, [
            h('a', { props: { href: 'javascript:;' } }, '删除')
          ])

        ];
      },
    }
  ];
}