'strict'

import moment from 'moment';

export default {
  date(date, format = 'YYYY-MM-DD hh:mm:ss') {
    return moment(date).format(format);
  },


  // Stock
  market(code) {
    const markets = {
      SH: '上海证券交易所',
      SZ: '深圳证券交易所'
    }
    return markets[code.split('.')[0]];
  },
  stock_code(code) {
    return code.split('.')[1];
  }
}