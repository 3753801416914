<template lang="pug">
//- https://preview.pro.ant.design/list/table-list/
div
  a-dropdown(v-model="visible" :trigger="['click']" placement="bottomRight")
    a.btn-icon-link
      a-icon.icon(:type="data.icon")
    .columns-center(slot="overlay")
      a-tree(
        :block-node="tree.blockNode"
        :default-expand-all="tree.defaultExpandAll"
        :default-checked-keys="tree.defaultCheckedKeys"
        :checkable="tree.checkable"
        :selectable="tree.selectable"
        :draggable="tree.draggable"
        :tree-data="columns"
        @dragenter="onDragEnter"
        @drop="onDrop")

      hr(style="border-bottom:none; opacity:0.35;")
      pre(style="font-size:11px;") {{ columns }}
</template>

<script>
export default {
  name: 'TableColumns',
  props: {
    data: {
      type: Object,
      default: null
    },
  },
  computed: {
  },
  data() {
    return {
      visible: false,
      columns: [],
      tree: {
        blockNode: true,
        checkable: true,
        defaultCheckedKeys: [ 'column-header' ],
        defaultExpandAll: true,
        selectable: false,
        draggable: true
      }
    }
  },
  methods: {
    onDragEnter(info) {
      console.log(info);
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    onDrop(info) {
      console.log(info);
      /*
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };
      const data = [...this.gData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.push(dragObj);
        });
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.gData = data;
      */
      // this.$emit('change', { [this.data.key]: e.key });
    },
  },
  created() {
    if (this.data && this.data.data) {
      const children = this.data.data.map(item => {
        return {
          title: item.title,
          key: item.key
        }
      });
      this.columns = [{
        title: '列展示',
        key: 'column-header',
        children
      }];
    }
  }
}
</script>

<style lang="less">
.columns-center {
  margin-top: 10px;
  background:#fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  position: relative;
  border-radius:4px; padding: 20px;
  
  .ant-tree {
    > li {
      padding: 0;

      span.ant-tree-switcher { display: none;}
      
      ul {
        padding: 0;
      }

      .ant-tree-child-tree {
        > li {
          padding: 0 0 4px 18px;
        }
      }
      
    }

    &.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
      width: 100%;
    }
  }
}

</style>